@import '~styles/utils/modules-entry';

.content {
  text-align: center;

  h1 {
    margin-bottom: 0.5rem;
    font-size: $font-size-xlg;
  }
}

.topIcon {
  width: 8rem;
  height: 8rem;
  margin: 0 auto;
  color: #f31c25;

  :global {
    animation: pulse 1.5s infinite;
  }
}

.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 0.5rem;
  text-align: center;

  a {
    flex: 1 0 31%;
    height: 8rem;
    padding: 1rem;
    margin: 0 1% 0.5rem;
    font-weight: bold;
    line-height: 1.2;
    color: #fff;
    transition: $transition-base;

    &:hover {
      color: #fff;
    }

    &.telegram {
      $button-bg: #3aa8db;

      background: $button-bg;

      &:hover {
        background: lighten($button-bg, 15);
      }
    }

    &.github {
      $button-bg: #000;

      background: $button-bg;

      &:hover {
        background: lighten($button-bg, 15);
      }
    }

    &.email {
      $button-bg: theme-color();

      background: $button-bg;

      &:hover {
        background: lighten($button-bg, 15);
      }
    }
  }

  svg {
    display: block;
    width: 3.5rem;
    height: 3.5rem;
    margin: 0.5rem auto 0.1rem;
  }
}
