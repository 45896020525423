@import '~styles/utils/modules-entry.scss';

.header {
  text-align: center;

  svg {
    width: 5rem;
    height: 5rem;
    color: theme-color(success);
  }

  h3 {
    margin: 0.4rem 0;
    font-weight: $font-weight-bold;
    font-size: 1.4rem;
  }

  @include media-breakpoint-down(sm) {
    br {
      display: none;
    }
  }
}

.linkInputGroup {
  // Leave some space for the copy status
  margin-bottom: 2rem;
}

.copyStatus {
  position: absolute;
  right: 0;
  bottom: -2rem;
  left: 0;
  margin: 0;
  text-align: center;
  color: theme-color(success);

  :global {
    animation: fadeIn 0.2s forwards;
  }
}

.shareHeading {
  margin: 1rem 0 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
}

.buttonContainer {
  width: 58px;
}

.qrCode {
  max-width: 12rem;
  padding: 0.2rem;
  margin: 0 auto;
  background: #fff;

  svg {
    display: block;
    margin: 0;
  }
}
