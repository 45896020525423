@use '@material/button';
@import '~styles/utils/modules-entry';

.snackbar:global(.mdc-snackbar) {
  z-index: $snackbar-z-index;
}

.notificationAction {
  @include button.ink-color(theme-color());
}
